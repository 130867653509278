import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;
  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 992.000000 1080.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="translate(0.000000,1080.000000) scale(0.100000,-0.100000)">
	<path d="M8778 10153 c12 -2 32 -2 45 0 12 2 2 4 -23 4 -25 0 -35 -2 -22 -4z"/>
<path d="M8926 10139 c9 -11 23 -19 31 -19 15 0 17 -12 4 -25 -5 -6 -11 -332
-14 -737 -3 -400 -8 -732 -12 -738 -3 -5 -16 -10 -29 -10 -17 0 -34 17 -67 66
-24 37 -50 79 -58 93 -8 14 -17 28 -21 31 -11 7 -100 141 -100 150 0 4 -14 25
-31 46 -17 22 -47 64 -66 94 -20 30 -40 58 -45 61 -8 5 -28 38 -60 99 -7 14
-15 27 -19 30 -10 9 -85 124 -102 156 -9 17 -29 45 -46 63 -17 18 -31 38 -32
45 0 6 -16 31 -35 56 -19 25 -39 56 -46 70 -7 14 -23 38 -35 53 -13 16 -23 33
-23 38 0 5 -9 19 -20 31 -11 12 -20 28 -20 35 0 7 -4 13 -10 13 -5 0 -22 21
-37 48 -15 26 -35 53 -45 60 -10 7 -18 19 -18 25 0 7 -20 39 -45 72 -25 33
-45 65 -45 72 0 32 -41 35 -446 31 -222 -2 -406 -7 -410 -10 -3 -4 6 -14 21
-24 16 -10 22 -21 17 -26 -17 -17 -1 -46 26 -51 15 -3 29 -1 30 4 6 15 22 -1
22 -21 0 -10 6 -20 14 -23 8 -3 19 -22 26 -41 6 -20 18 -36 25 -36 8 0 26 -25
41 -56 14 -30 35 -60 45 -67 10 -6 19 -19 19 -27 1 -8 14 -33 30 -55 16 -22
43 -65 61 -95 18 -30 41 -61 51 -68 10 -7 18 -20 18 -29 0 -9 6 -24 14 -32 22
-26 86 -120 87 -128 0 -5 25 -42 55 -83 29 -41 63 -94 75 -117 12 -24 25 -43
30 -43 5 0 9 -4 9 -10 0 -5 37 -64 83 -130 45 -67 88 -133 96 -148 8 -15 17
-29 21 -32 3 -3 10 -12 13 -20 4 -8 23 -35 42 -60 19 -25 38 -52 42 -60 13
-28 93 -149 103 -155 6 -3 10 -16 10 -28 0 -12 32 -71 71 -130 62 -95 74 -107
99 -107 36 0 382 95 423 116 16 9 53 22 81 30 149 41 186 57 187 78 2 104 -3
1500 -7 1563 -4 79 -3 83 21 99 14 9 40 20 58 24 21 5 -4 7 -71 8 -100 0 -102
-1 -86 -19z"/>
<path d="M9138 10153 c12 -2 32 -2 45 0 12 2 2 4 -23 4 -25 0 -35 -2 -22 -4z"/>
<path d="M7037 10023 c-2 -5 -4 -118 -5 -253 -3 -452 -14 -1115 -20 -1124 -2
-5 -7 -142 -9 -305 -3 -169 -9 -300 -14 -306 -6 -5 -52 -12 -104 -13 -102 -4
-129 -17 -100 -46 30 -29 464 -18 515 14 29 18 -4 30 -83 30 -135 0 -127 -20
-127 309 0 153 -4 291 -9 307 -7 21 -25 1151 -22 1367 0 25 -13 37 -22 20z"/>
<path d="M5950 9912 c0 -11 65 -73 71 -68 3 3 6 -4 6 -16 3 -34 17 -38 36 -12
17 23 17 24 -3 24 -17 0 -20 6 -20 40 0 33 -3 40 -19 40 -11 0 -21 -6 -24 -12
-3 -10 -9 -10 -26 0 -11 7 -21 9 -21 4z"/>
<path d="M6201 9863 c3 -20 -2 -24 -17 -12 -14 11 -16 10 -13 -16 4 -40 -9
-75 -27 -75 -8 0 -14 5 -14 10 0 6 -8 10 -18 10 -10 0 -23 5 -30 12 -27 27
-11 -41 19 -78 17 -22 37 -53 44 -70 7 -17 24 -42 39 -57 14 -14 26 -31 26
-37 0 -5 7 -10 15 -10 15 0 20 21 8 34 -8 8 -12 69 -12 193 -1 52 -5 94 -11
98 -6 4 -10 3 -9 -2z m-45 -141 c-9 -9 -37 8 -31 18 5 8 11 7 21 -1 8 -6 12
-14 10 -17z"/>
<path d="M6135 9850 c-3 -6 1 -7 9 -4 18 7 21 14 7 14 -6 0 -13 -4 -16 -10z"/>
<path d="M1156 9508 c-3 -7 -7 -304 -10 -661 l-6 -647 22 -8 c13 -4 182 -6
376 -4 l353 4 9 22 c4 13 5 193 1 402 -3 208 -5 384 -3 390 6 20 48 34 65 21
12 -10 144 -206 207 -307 8 -14 29 -47 45 -74 247 -398 290 -462 324 -475 14
-5 80 -13 146 -16 147 -8 152 -9 160 -44 3 -16 28 -53 54 -82 l48 -54 366 -2
c310 -2 366 0 371 12 8 22 -6 26 -110 28 -85 2 -98 5 -113 24 -9 12 -27 36
-40 53 -13 17 -39 60 -56 95 -18 35 -48 86 -66 112 -19 26 -40 57 -46 68 -7
11 -43 70 -79 130 -37 61 -79 125 -93 144 -26 34 -59 86 -170 261 -71 112
-120 167 -158 176 -16 4 -37 13 -48 20 -11 8 -30 14 -42 14 -13 0 -23 5 -23
11 0 5 -5 7 -10 4 -11 -7 -136 20 -190 40 -19 8 -51 17 -70 20 -19 4 -45 13
-57 21 -12 8 -35 14 -50 14 -15 1 -46 9 -67 19 -35 17 -44 17 -78 5 -38 -13
-74 -41 -126 -96 -28 -31 -76 -38 -86 -12 -3 9 -6 47 -6 85 0 61 -3 73 -25 93
-13 12 -55 30 -92 39 -71 18 -126 34 -233 66 -36 11 -92 27 -125 36 -110 30
-170 46 -201 56 -42 12 -64 11 -68 -3z"/>
<path d="M4660 9349 c0 -6 4 -18 9 -27 5 -10 6 -41 1 -72 -7 -52 -8 -53 -9
-14 0 22 -3 43 -6 46 -3 2 -37 0 -77 -5 -68 -9 -98 -23 -98 -46 0 -6 -9 -11
-20 -11 -11 0 -20 -4 -20 -10 0 -5 -15 -10 -34 -10 -18 0 -39 -7 -46 -15 -7
-8 -19 -15 -26 -15 -8 0 -14 -4 -14 -9 0 -6 -23 -11 -52 -13 -75 -4 -77 -28
-1 -29 42 -1 51 -3 33 -8 -14 -4 -92 -7 -173 -6 -94 1 -151 -3 -156 -9 -17
-22 -20 -146 -6 -228 48 -294 101 -413 256 -575 82 -86 121 -115 239 -176 179
-94 320 -134 585 -167 116 -14 463 -8 565 10 36 6 88 15 115 20 51 9 61 11
175 35 211 45 290 41 384 -18 83 -53 81 -64 84 344 3 305 1 364 -14 428 l-17
74 23 19 c16 13 39 18 81 18 48 0 57 3 53 15 -5 13 -72 15 -543 13 -469 -3
-536 -5 -536 -18 0 -13 17 -14 105 -12 77 3 109 0 120 -10 14 -11 15 -51 8
-338 -8 -338 -18 -442 -46 -474 -9 -10 -35 -28 -57 -40 -36 -19 -56 -21 -195
-21 l-155 0 -75 38 c-106 53 -190 137 -240 237 -25 51 -59 143 -67 180 -2 14
-11 45 -18 70 -16 53 -41 247 -50 373 -3 49 -10 98 -16 108 -6 11 -10 81 -10
161 1 150 -9 198 -41 198 -10 0 -18 -5 -18 -11z m-117 -226 c-13 -2 -33 -2
-45 0 -13 2 -3 4 22 4 25 0 35 -2 23 -4z m-143 -39 c0 -11 -5 -14 -16 -10 -9
3 -20 6 -25 6 -5 0 -9 5 -9 10 0 6 11 10 25 10 16 0 25 -6 25 -16z"/>
<path d="M2220 9305 l-23 -25 49 1 c45 1 47 1 21 11 -22 8 -26 13 -16 24 20
25 -7 15 -31 -11z"/>
<path d="M2480 9290 c0 -6 7 -10 15 -10 8 0 15 2 15 4 0 2 -7 6 -15 10 -8 3
-15 1 -15 -4z"/>
<path d="M2570 9255 c-11 -13 -8 -15 20 -15 21 0 30 4 28 13 -7 18 -33 20 -48
2z"/>
<path d="M2641 9207 c3 -27 -2 -37 -18 -37 -10 0 -13 -3 -7 -9 5 -5 24 -7 42
-4 26 4 32 10 30 26 -3 22 -50 46 -47 24z"/>
<path d="M7208 7243 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
<path d="M7280 7190 c0 -5 10 -14 22 -21 18 -10 20 -9 15 6 -4 9 -7 19 -7 21
0 2 -7 4 -15 4 -8 0 -15 -4 -15 -10z"/>
<path d="M7359 7138 c-8 -28 -4 -40 15 -48 13 -5 26 -16 29 -24 4 -9 13 -16
22 -16 8 0 15 -6 15 -13 0 -7 9 -18 20 -25 11 -7 20 -21 20 -32 0 -11 5 -20
10 -20 6 0 10 -5 10 -11 0 -6 14 -24 30 -41 17 -16 30 -33 30 -38 0 -5 9 -12
20 -15 15 -5 20 -15 20 -38 0 -23 6 -35 22 -42 13 -6 28 -28 37 -56 10 -29 21
-44 29 -41 7 2 16 -5 19 -17 3 -12 9 -21 13 -21 4 0 10 -18 14 -40 3 -22 11
-40 16 -40 6 0 10 -11 10 -24 0 -19 -8 -27 -36 -36 l-36 -12 43 -25 c51 -28
61 -41 53 -66 -3 -10 -1 -23 4 -30 20 -26 32 -164 31 -385 0 -98 3 -142 11
-137 6 4 10 -10 10 -39 0 -25 -4 -46 -9 -46 -5 0 -11 -19 -15 -42 -3 -24 -10
-68 -15 -98 -6 -30 -13 -72 -17 -92 -3 -21 -10 -38 -15 -38 -12 0 -12 -87 0
-95 6 -3 32 -8 58 -10 26 -3 63 -10 81 -16 44 -15 62 -6 62 30 0 17 6 50 14
73 41 127 37 502 -8 683 -8 33 -20 80 -26 105 -6 25 -20 64 -30 87 -11 24 -20
49 -20 56 0 7 -9 29 -20 50 -11 20 -20 43 -20 51 0 8 -7 21 -15 30 -8 8 -32
48 -52 88 -20 40 -43 79 -50 86 -7 7 -13 18 -13 23 0 10 -95 134 -137 179 -12
14 -53 59 -90 100 -37 41 -75 79 -85 84 -10 5 -18 15 -18 21 0 13 -37 34 -41
23z m478 -1175 c-2 -21 -4 -4 -4 37 0 41 2 58 4 38 2 -21 2 -55 0 -75z"/>
<path d="M5340 6460 c0 -8 96 -157 110 -170 3 -3 23 -31 45 -64 100 -147 171
-239 296 -380 63 -71 119 -135 124 -144 20 -31 71 -71 89 -72 10 0 41 20 67
45 26 25 55 45 64 45 9 0 98 -40 198 -89 100 -49 211 -102 247 -116 36 -15 70
-31 75 -35 6 -4 26 -11 45 -15 19 -4 44 -13 55 -20 35 -22 157 -67 181 -67 13
0 24 -4 24 -9 0 -5 8 -9 17 -9 9 0 36 -9 59 -20 23 -12 68 -25 100 -30 33 -5
60 -14 62 -20 4 -12 59 -13 66 -1 4 5 0 12 -6 14 -10 4 -10 6 0 6 7 1 10 6 6
12 -3 6 0 15 7 20 10 6 6 9 -13 9 -47 0 -58 9 -58 51 l0 39 43 0 c25 0 54 8
72 20 l29 19 -39 16 c-22 9 -52 22 -67 30 -14 8 -35 15 -46 15 -11 0 -25 6
-31 14 -7 8 -27 18 -44 22 -18 3 -36 10 -42 14 -5 4 -28 13 -50 20 -22 7 -53
21 -70 31 -16 11 -40 19 -52 19 -13 0 -23 3 -23 8 0 4 -15 13 -32 21 -67 28
-123 56 -126 64 -2 4 -12 7 -23 7 -11 0 -37 11 -59 25 -22 14 -44 25 -49 25
-5 0 -16 6 -23 13 -7 7 -49 31 -93 54 -101 52 -107 72 -43 132 37 35 41 42 28
54 -8 8 -35 19 -60 26 -50 13 -220 65 -290 88 -137 44 -481 175 -574 217 -92
42 -175 76 -186 76 -5 0 -10 -4 -10 -10z m1890 -990 c0 -5 -7 -10 -15 -10 -8
0 -15 5 -15 10 0 6 7 10 15 10 8 0 15 -4 15 -10z m-86 -115 c-7 -16 -24 -21
-24 -6 0 8 7 13 27 20 1 1 0 -6 -3 -14z"/>
<path d="M9078 5864 c-51 -49 -96 -95 -99 -103 -4 -9 -10 -43 -14 -76 -4 -32
-16 -81 -26 -108 -41 -102 -15 -103 87 0 60 59 85 94 115 157 40 86 61 197 40
210 -6 4 -51 -31 -103 -80z"/>
<path d="M4300 5505 c0 -9 42 -80 66 -112 13 -17 24 -35 24 -38 0 -4 12 -21
26 -39 14 -17 50 -65 80 -106 85 -118 85 -119 162 -214 131 -162 290 -336 306
-336 6 0 33 20 60 45 33 30 58 45 76 45 15 0 108 -40 206 -89 218 -108 269
-131 287 -131 8 0 20 -6 26 -13 6 -8 30 -20 53 -27 24 -7 61 -21 83 -30 23
-10 61 -23 85 -30 25 -7 50 -15 55 -19 38 -29 141 -57 217 -60 15 0 34 -9 43
-18 14 -14 36 -16 131 -14 93 1 114 -1 114 -13 0 -9 7 -12 20 -9 20 5 29 43
10 43 -15 0 -12 40 3 41 6 0 2 5 -10 11 -13 6 -23 15 -23 20 0 4 -18 8 -40 8
-22 0 -40 -4 -40 -10 0 -5 -7 -10 -17 -10 -15 0 -15 1 0 18 10 10 17 24 17 30
0 7 16 12 41 12 36 0 42 -3 52 -30 15 -40 40 -38 55 3 17 46 17 45 -30 57 -78
20 -183 53 -203 66 -11 6 -45 20 -75 29 -30 10 -83 31 -118 46 -34 16 -67 29
-73 29 -6 0 -23 6 -37 14 -67 34 -228 106 -239 106 -7 0 -13 5 -13 10 0 6 -5
10 -10 10 -12 0 -42 15 -183 89 -132 69 -137 73 -137 96 0 11 20 40 45 64 25
24 45 47 45 51 0 4 -30 15 -67 25 -71 18 -424 130 -458 145 -11 5 -63 25 -115
45 -52 19 -129 49 -170 67 -41 17 -88 36 -105 43 -16 7 -68 29 -115 49 -79 34
-110 43 -110 31z"/>
<path d="M7418 5443 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M7360 5250 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M7633 5135 c-12 -8 -27 -13 -35 -12 -7 2 -10 2 -5 0 13 -8 7 -32 -18
-70 -39 -60 -68 -93 -82 -93 -7 0 -13 -6 -13 -12 0 -32 -22 -88 -35 -88 -17 0
-125 -110 -125 -127 0 -12 -12 -17 -26 -10 -3 1 -15 -14 -27 -33 -22 -35 -118
-130 -131 -130 -4 0 -24 -17 -45 -39 -20 -21 -57 -49 -82 -61 l-44 -23 35 -15
c41 -17 169 -38 257 -41 l62 -2 98 98 c54 54 123 127 153 162 30 35 60 69 66
75 5 6 19 27 30 46 10 19 32 51 47 70 43 54 47 62 109 180 53 103 49 125 -24
134 -86 10 -142 7 -165 -9z"/>
<path d="M8104 4958 c-22 -18 -67 -59 -100 -90 -59 -56 -61 -60 -73 -130 -16
-92 -16 -94 -36 -147 -9 -24 -14 -45 -12 -47 10 -11 72 34 124 89 70 75 115
164 137 271 9 43 16 80 16 82 0 11 -21 1 -56 -28z"/>
<path d="M3087 4874 c7 -3 17 -21 23 -40 6 -19 18 -34 26 -34 9 0 13 -4 9 -9
-3 -5 -1 -12 5 -16 6 -4 10 7 10 28 0 37 -36 78 -68 76 -9 0 -12 -3 -5 -5z"/>
<path d="M3187 4743 c-9 -14 17 -58 38 -67 7 -2 15 1 18 7 7 20 -4 37 -23 37
-10 0 -20 8 -22 18 -2 12 -6 14 -11 5z"/>
<path d="M3269 4654 c-10 -13 -10 -17 1 -24 12 -7 12 -15 2 -43 -12 -33 -10
-38 35 -113 27 -44 69 -108 93 -143 25 -35 52 -73 60 -85 77 -110 263 -338
343 -421 22 -22 51 -55 65 -73 44 -58 56 -59 116 -8 37 32 62 46 82 46 17 0
101 -36 194 -82 161 -81 187 -93 335 -156 304 -130 696 -249 777 -236 24 4 43
2 53 -6 13 -11 15 -9 15 13 0 14 -6 28 -12 30 -10 4 -10 6 0 6 6 1 12 7 12 15
0 9 17 15 51 18 50 5 73 21 64 45 -2 6 1 14 8 16 19 7 -5 27 -44 37 -19 5 -56
16 -84 25 -27 9 -70 23 -95 31 -25 9 -63 20 -85 25 -22 6 -44 14 -49 20 -6 5
-16 9 -24 9 -14 0 -110 39 -220 91 -47 22 -63 34 -60 47 4 21 10 21 238 -3
430 -44 920 21 1365 182 146 53 431 192 463 226 l23 24 -128 12 c-70 6 -149
14 -175 17 -36 5 -58 1 -90 -14 -42 -19 -81 -31 -123 -36 -11 -1 -29 -5 -40
-8 -11 -3 -36 -9 -56 -12 -20 -4 -47 -15 -59 -26 -13 -11 -35 -20 -51 -20 -15
0 -29 -4 -31 -9 -5 -14 -189 -70 -253 -77 -33 -4 -63 -11 -66 -17 -4 -5 -39
-12 -78 -15 -39 -2 -74 -9 -77 -13 -3 -5 -23 -9 -45 -9 -21 0 -39 -4 -39 -10
0 -6 -44 -10 -112 -10 -74 0 -128 -5 -155 -15 -37 -13 -48 -13 -84 -1 -23 8
-46 13 -53 11 -27 -9 -226 -13 -232 -4 -3 5 -31 9 -63 9 -31 0 -61 4 -67 9 -5
5 -36 11 -69 12 -33 1 -64 5 -70 8 -13 7 -26 9 -105 15 -94 8 -132 16 -155 36
-18 16 -97 37 -118 32 -17 -5 6 62 26 79 29 23 12 42 -51 60 -26 7 -49 15 -52
19 -3 3 -40 14 -82 24 -43 10 -78 22 -78 27 0 5 -9 9 -19 9 -10 0 -61 15 -112
34 -52 19 -110 40 -129 47 -19 7 -53 20 -75 29 -22 10 -53 20 -70 24 -16 4
-38 15 -48 24 -11 9 -24 17 -30 17 -7 0 -37 12 -67 26 -30 15 -94 45 -141 68
-47 22 -89 41 -93 41 -4 0 -18 7 -32 16 -19 13 -22 20 -13 30 8 10 4 19 -17
39 -32 30 -28 29 -45 9z"/>
<path d="M6530 4450 c0 -5 15 -10 33 -9 27 0 29 2 12 9 -28 12 -45 12 -45 0z"/>
<path d="M6488 4403 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M6698 4403 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M7055 3987 c-16 -18 -56 -55 -89 -83 -54 -47 -59 -56 -67 -106 -5
-30 -20 -89 -34 -132 -30 -90 -29 -86 -10 -86 26 0 144 122 185 190 21 36 45
88 55 117 17 54 17 124 0 130 -5 1 -23 -12 -40 -30z"/>
<path d="M5595 3460 c-8 -13 20 -24 36 -15 8 6 7 10 -2 16 -18 11 -26 11 -34
-1z"/>
<path d="M5556 3384 c-9 -23 -8 -24 23 -24 24 0 28 3 24 20 -6 24 -38 27 -47
4z"/>
<path d="M5608 3273 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M6785 1695 c-276 -50 -444 -216 -491 -485 -34 -198 20 -423 129 -539
166 -175 507 -227 792 -120 91 34 105 53 105 144 0 91 -10 99 -79 65 -105 -51
-180 -70 -283 -70 -83 0 -108 4 -165 27 -120 48 -185 142 -205 299 -21 164 18
317 105 406 111 115 318 130 499 38 43 -22 85 -40 94 -40 37 0 46 149 10 188
-29 33 -132 73 -224 88 -104 17 -193 17 -287 -1z"/>
<path d="M2485 1693 c-68 -12 -56 4 -133 -183 -109 -264 -155 -376 -162 -390
-5 -8 -25 -55 -45 -105 -21 -49 -43 -103 -50 -120 -48 -108 -135 -335 -135
-352 0 -27 23 -33 127 -33 103 0 125 8 143 56 8 20 31 78 51 128 l35 91 265 2
c145 1 269 -1 274 -5 6 -4 33 -63 60 -131 40 -99 54 -125 75 -133 28 -11 244
-7 256 4 11 11 -9 70 -87 256 -39 92 -81 194 -95 227 -14 33 -29 69 -34 80 -5
11 -21 49 -36 85 -14 36 -32 79 -40 95 -13 30 -66 159 -126 307 -38 96 -58
118 -106 119 -183 5 -220 5 -237 2z m190 -420 c43 -109 86 -218 96 -242 32
-75 42 -71 -186 -71 -191 0 -205 1 -205 18 0 10 4 22 9 28 5 5 21 39 35 77 14
37 32 82 40 100 8 17 36 85 61 150 49 130 53 137 64 137 4 0 43 -89 86 -197z"/>
<path d="M5835 1695 c-5 -2 -27 -6 -47 -10 l-38 -6 0 -567 c0 -340 4 -573 10
-582 7 -12 34 -15 130 -15 96 0 123 3 130 15 16 24 13 1135 -2 1148 -13 10
-164 25 -183 17z"/>
<path d="M7942 1689 c-29 -4 -54 -9 -56 -10 -3 -6 -201 -485 -224 -544 -11
-27 -31 -75 -45 -105 -13 -30 -44 -104 -68 -165 -23 -60 -49 -119 -56 -130 -7
-11 -13 -25 -13 -32 0 -7 -11 -36 -24 -65 -54 -120 -49 -128 74 -128 146 0
145 0 180 94 17 44 38 95 46 113 8 17 14 38 14 46 0 8 6 17 13 20 8 3 131 6
274 6 201 1 262 -1 270 -11 6 -7 29 -62 52 -123 23 -60 52 -118 63 -127 17
-15 40 -18 127 -18 58 0 116 3 129 6 22 6 29 32 13 49 -5 6 -22 46 -39 90 -17
44 -52 130 -78 190 -25 61 -66 157 -89 215 -65 160 -95 231 -117 279 -11 24
-40 93 -65 155 -25 61 -52 128 -60 148 -13 30 -23 39 -47 43 -53 9 -218 11
-274 4z m138 -269 c11 -25 51 -126 90 -225 38 -99 75 -188 80 -197 6 -10 10
-22 10 -28 0 -6 -73 -10 -205 -10 -226 0 -219 -2 -190 62 8 18 40 98 71 178
31 80 64 163 74 185 10 22 21 50 25 63 10 32 24 24 45 -28z"/>
<path d="M3484 1682 c-32 -21 -34 -51 -34 -594 0 -324 4 -549 10 -558 13 -20
232 -22 252 -2 10 10 13 68 15 234 1 121 4 222 6 225 3 2 101 4 219 3 162 0
218 2 231 13 24 17 33 85 17 131 l-12 36 -218 0 c-120 0 -224 2 -232 5 -10 4
-13 39 -11 158 1 83 2 158 2 165 1 9 56 12 240 12 237 0 240 0 255 22 9 12 16
43 16 68 0 25 -7 56 -16 68 l-15 22 -357 0 c-196 0 -362 -4 -368 -8z"/>
<path d="M4501 1669 c-21 -23 -21 -27 -21 -580 l0 -558 38 -10 c59 -17 194
-14 220 5 23 16 23 17 21 242 l-1 227 68 3 c155 7 196 -30 324 -293 101 -207
86 -195 230 -195 107 0 140 8 140 32 0 32 -161 344 -208 405 -24 30 -63 67
-88 81 -24 15 -44 30 -44 33 0 4 26 16 58 28 83 32 142 79 177 142 27 48 30
63 29 139 0 57 -6 97 -18 123 -21 48 -85 110 -140 137 -100 48 -135 52 -457
57 l-307 5 -21 -23z m570 -176 c75 -34 109 -104 90 -186 -11 -46 -42 -81 -96
-110 -35 -18 -57 -21 -173 -22 l-133 0 0 163 c0 89 1 167 1 173 0 8 39 10 133
7 106 -3 141 -8 178 -25z"/>
</g>
</svg>

    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>
}
